/* dont remove this row styling */
@font-face {
  font-family: EB Garamond;
  src: url(../src/fontfamily/static/EBGaramond-Regular.ttf);
}

.custom-drawer {
  position: fixed;
  top: 0;
  right: -400px; /* Hide the drawer initially */
  width: 400px;
  height: 100%;
  background-color: white;
  transition: right 0.3s ease;
  z-index: 88765765;
  overflow: scroll;
}
.drawerContentBox {
  background-color: #a8dddc;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer-content {
  padding: 20px;
}
.modal-backdrop {
  z-index: 0 !important;
}
.drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.drawerHeader {
  display: flex;
  justify-content: space-between;
}
.Garamond {
  font-family: EB Garamond !important;
}
.notimodalContentBox {
  background-color: yellow;
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.row {
  margin: 0px !important;
  /* padding: 0px!important; */
}

.col {
  padding: 0 !important;
}

.grey {
  background-color: #efefef !important;
}

.blue {
  background-color: #5cd1d0 !important;
}

.blue_text {
  color: #17a9a9 !important;
}

.green_text {
  color: #056363 !important;
}

.app_font {
  font-family: Roboto Slab;
}

.imageCircle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.sideButton {
  border-radius: 1px;
  height: 30px;
  width: 109%;
  background-color: inherit;
  border: none;
  margin-top: 1.5rem !important;
}

.sideText {
  font-family: Roboto Slab;

  font-size: 18px;
  color: #056363;
}

/* .sideTextInventory {
    font-family: Roboto Slab;
  
    font-size: 20px;
    padding-right: 37px;
  }
  .sideTexthr {
    font-family: Roboto Slab;
  
    font-size: 20px;
    padding-right: 29px;
  }
  .sideTextassets {
    font-family: Roboto Slab;
  
    font-size: 20px;
    padding-right: 22px;;
  } */
.sub_button_style {
  height: 100px;
  width: 13em;
  background: white;
  box-shadow: 7px 8px 0px #caf5f5;
  border-radius: 10px;
  border: none !important;
  outline: none !important;
  margin-bottom: 13px;
}

.sub_button_style_underline {
  height: 103px;
  width: 13em;
  background: white;

  border-radius: 10px;
  border: none !important;
  outline: none !important;
  box-shadow: 7px 7px 0px #21b6a8;
  margin-bottom: 13px;
}

.fullHeight {
  height: 100%;
}

.sidebuttonsheight {
  height: 100vh;
  padding-top: 40px;
}

.main_btn_style {
  background-color: #5cd1d0;
  border-top-right-radius: 35px;
}

.designation_head {
  font: normal normal normal 9px Noto Serif;
  position: absolute;
}

.name_heading {
  font: normal normal bold 32px/32px Sitka Display;
}

.top_menu_btn {
  background-color: #efefef !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
  font: Roboto Slab;
  outline: none !important;

  /* font: normal normal normal 22px/20px Roboto Slab; */
  font: normal normal normal 1.3vw Roboto Slab;
}

.top_menu_btn-select {
  background-color: #efefef !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #056363;
  border-bottom-color: aqua !important;
  outline: none !important;
  /* font: normal normal normal 22px/20px Roboto Slab; */

  font: normal normal normal 1.3vw Roboto Slab;
}

.nav-space {
  display: flex !important;
  /* justify-content: space-between !important; */
  gap: 50px;
  padding-top: 5px;
  padding-left: 50px !important;
  padding-right: 0px !important;
}

.side_button_hover:hover {
  background-color: #efefef !important;
}

.Icondashboard {
  width: 18px;
  margin-top: 6px;
}

.Iconpayable {
  width: 18px;
  margin-top: 6px;
}

.Iconhome {
  width: 25px;
  margin-top: 6px;
}

.Iconloan {
  width: 20px;
  margin-top: 7px;
}

.Icontax {
  width: 20px;
  margin-top: 2px;
}

.Icontaxbell {
  width: 15px;
  margin-top: 10px;
}

.Icongroup {
  margin-top: 17px;
  margin-right: 18px;
  float: right;
  width: 9px;
}

.line_styling {
  position: relative;
  top: 43px;
  color: #056363 !important;
}

.accountBtn {
  width: -10px !important;
  background: none !important;
  border: none !important;
  outline: none !important;
}

.drop_icon_style {
  font-size: 2.1em !important;
  color: #056363 !important;
  top: -10px;
}

.right_nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dropdown-toggle::after {
  display: none !important;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-success:focus {
  box-shadow: none !important;
}

.loginbox {
  background-color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  height: 355px;
  width: 408px;
  padding: 15px 10px;
  border-radius: 20px;
  position: relative;
  top: 22vh;

  /* padding-bottom: 60px; */
  background: #ffffffd4 0% 0% no-repeat padding-box;
}

.logintext {
  font: normal normal normal 53px/80px Roboto Slab;

  letter-spacing: 0px;
  color: #298b8b;
  width: 143px;
  height: 70px;
}

.logininput {
  width: 352px;

  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-color: #0b6462;
  outline: none !important;
  background: none;
  font-size: larger;
}

.logIn {
  font: normal normal normal 21px/26px Roboto Slab;

  width: 360px;
  height: 49px;
  background: #298b8b;
  outline: none !important;
  border: none !important;
  color: white;
  border-radius: 5px;
}

.password {
  font: normal normal bold 19px/24px Roboto Slab;
  letter-spacing: 0.8px;
  color: #000000;
}

.select_user {
  margin-top: 10%;
}

.space {
  width: 290px;

  height: 70px;
}

.input1 {
  font-size: 25px;

  border: 1px double;
  border-radius: 5px;
  outline: none !important;
  font-family: montserrat-medium;
  text-align: center;

  margin: 6px !important;
}

.verfiytext {
  font: normal normal normal 19px/24px Roboto Slab;
  letter-spacing: 0px;
  color: #000000;
  width: 410px;
  height: 25px;
}

.forgottext {
  font: normal normal normal 35px/53px Roboto Slab;

  letter-spacing: 0px;
  color: #298b8b;
  width: 143px;
  height: 70px;
}

.m_top_custom {
  margin-top: 5rem;
}

a:-webkit-any-link {
  text-decoration: none !important;
}

.dashboardtoprow {
  box-shadow: 0px 3px 6px #00000057;
  border-radius: 9px;
  margin-top: 20px;

  height: 33vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
}

.dashboardboxsmall {
  box-shadow: 0px 3px 6px #00000057;
  border-radius: 9px;
  margin-left: -4px;
  margin-top: 20px;
  /* height: 13vh; */
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
}
.dashboardboxsmallAccount {
  box-shadow: 0px 3px 6px #00000057;
  border-radius: 9px;
  padding: 16px;
  width: 150px;
  /* margin-left: 10px; */
  margin-top: 20px;
  /* height: 13vh; */
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
}

.dash_background {
  background-color: white;
  /* border: 1px solid grey; */
  border-radius: 6px;
  height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.w_48 {
  width: 49.3%;
}

.chart_account {
  margin-top: 2%;
  width: auto !important;
}

.h_47 {
  height: 47px;
}

.w_20 {
  width: 23.7%;
}

@media screen and (max-width: 1400px) {
  .top_menu_btn {
    background-color: #efefef !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
    font: Roboto Slab;
    outline: none !important;

    /* font: normal normal normal 22px/20px Roboto Slab; */
    font: normal normal normal 1.5vw Roboto Slab;
  }

  .top_menu_btn-select {
    background-color: #efefef !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    color: #056363;
    border-bottom-color: aqua !important;
    outline: none !important;
    /* font: normal normal normal 22px/20px Roboto Slab; */

    font: normal normal normal 1.5vw Roboto Slab;
  }

  .w_48 {
    width: 49% !important;
    margin-top: 4px;
  }

  .w_20 {
    width: 23% !important;
  }
}

@media screen and (max-width: 1000px) {
  .w_48 {
    width: 45% !important;
    margin-top: 4px;
  }

  .w_20 {
    width: 23% !important;
  }
}

@media screen and (max-width: 600px) {
  .w_48 {
    width: 45% !important;
    margin-top: 4px;
  }

  .w_20 {
    width: 23% !important;
  }
}

/* On screens that are 600px or less, set the background color to olive */

.input_search_styling {
  height: 23px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 50%;
  outline: none !important;
}

.input_total_styling1 {
  height: 23px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 160px;
  outline: none !important;
}

.input_total_styling {
  height: 23px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 85%;
  outline: none !important;
}

.tdbordernone {
  border: none !important;
}

.changeform {
  height: 90vh;
}

.input_styling {
  height: 28px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 85%;
  outline: none !important;
}

.input_address {
  height: 80px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 85%;

  outline: none !important;
}

.input_desc {
  width: 99% !important;
}

.input_date_styling {
  height: 23px;
  /* background: #EFEFEF 0% 0% no-repeat padding-box; */
  border: 1px solid #707070;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;

  outline: none !important;

  width: 85%;
}

.input_print_styling {
  height: 23px;
  /* background: #EFEFEF 0% 0% no-repeat padding-box; */
  border: 1px solid #707070;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font: normal normal normal 20px Roboto Slab;

  outline: none !important;

  width: 70%;
}

.input_date {
  height: 23px;
  /* background: #EFEFEF 0% 0% no-repeat padding-box; */
  border: 1px solid #707070;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  outline: none !important;

  width: 65%;
}

.btn_Go {
  background: #298b8b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  border: none !important;
  outline: none !important;
  color: white;
}

.input_label {
  font: normal normal normal 16px Roboto Slab;
}

/* .label_print{
  font: normal normal normal 29px Roboto Slab;
} */
.label_print {
  font: normal normal normal 27px Roboto Slab;
}

.label_print_td {
  font: normal normal normal 25px Roboto Slab !important;
}

.label_print2 {
  font: normal normal normal 22px Roboto Slab !important;
}

tr:nth-child(even) {
  background-color: #e4f8f8;
}

.table {
  height: fit-content !important;
  width: 100% !important;
  border-collapse: collapse !important;
  -webkit-overflow-scrolling: touch;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.table thead tr {
  position: sticky;
  top: 0;
  /* z-index: 9; */
  height: 35px;
  background-color: #caf5f5;
}

.table thead th {
  padding: 7px !important;
}

.table td {
  padding: 7px !important;
  font-size: 15px;
}

th {
  color: #0b6462 !important;
  font: normal normal normal 16px Roboto Slab;
}

.table-responsive {
  border-radius: 7px;
  border: 1px solid #caf5f5;
  overflow: auto;
  height: 75vh;
  max-width: inherit !important;
}
.table-responsive-print {
  border-radius: 7px;
  border: 1px solid #caf5f5;
  overflow: auto;
  height: 90%;
  max-width: inherit !important;
}
.vouchers_table_height {
  height: 70vh !important;
}

.table-responsive_modal {
  border-radius: 7px;
  border: 1px solid #caf5f5;
  overflow: auto;
  max-height: 75vh;
}

.table_height-voucher {
  overflow: auto;
  height: 30vh;
}

.table_height-land {
  overflow: auto;
  height: auto;
}

.delete_icon {
  cursor: pointer;
  width: 19px;

  border: none !important;
}

.add_icon {
  color: #0b6462;
  font-size: xx-large;
  cursor: pointer;
}

.save_btn {
  border: none !important;
  outline: none !important;
  background-color: #4ad187;
  font: normal normal normal 17px/23px Roboto Slab;
  border-radius: 5px;
}

.print_btn {
  border: none !important;
  outline: none !important;
  background-color: #2eccbd;
  font: normal normal normal 17px/23px Roboto Slab;
  border-radius: 5px;
}

.headings {
  font: normal normal normal 20px Sitka Text;
  letter-spacing: 0px;
  color: #298b8b;
}

.comp_btns {
  width: 16.1%;
  height: 36px;

  background: #e4f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #21b6a8;
  border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.comp_btns_text {
  font: normal normal normal 1vw Roboto Slab;
  letter-spacing: 0px;
  color: #0b6462;
}

.comp_btns_selected {
  width: 16.1%;
  height: 36px;

  background: #e4f8f8 0% 0% no-repeat padding-box;

  border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.adjust_btns {
  width: 49.5%;
  height: 36px;

  background: #e4f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #21b6a8;
  border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.adjust_btns_selected {
  width: 49.5%;
  height: 36px;

  background: #e4f8f8 0% 0% no-repeat padding-box;

  border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.sub_btn_number {
  font: 18px/24px Roboto Slab;
  letter-spacing: 0px;
  color: #1e1e1e;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
.Defaultmodal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.watermarked {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 25%;
  top: 10%;
  opacity: 0.1;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: 50%;
  border: 1px solid #888;
  width: 80% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}
.modal-content1 {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: 50%;
  border: 1px solid #888;
  width: 100% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

.modal-content-cat {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: 40%;
  border: 1px solid #888;
  width: 50% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

.modal-content-Alert {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: "fit-content";
  border: 1px solid #888;
  max-width: 600px !important;
  /* Could be more or less, depending on screen size */
  width: 100% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

.modal-content-deal {
  background-color: #fefefe;
  margin: 4% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  /* Could be more or less, depending on screen size */
  overflow: auto;
  height: 80%;
}

.modal-content-cat_voucher {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: 50%;
  border: 1px solid #888;
  width: 50% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}
.modal-content-cat_voucher2 {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: 40%;
  border: 1px solid #888;
  width: 40% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
  padding-bottom: 10px;
}

.revertModal {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  height: fit-content;
  border: 1px solid #888;
  width: 50% !important;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal_heading {
  box-shadow: 0px 3px 6px var(--unnamed-color-21b6a8);
  background: #caf5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #21b6a8;
  border: 1px solid #707070;

  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_heading {
  box-shadow: 0px 3px 6px var(--unnamed-color-21b6a8);
  background: #caf5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #21b6a8;
  border: none !important;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_headingAlert {
  /* box-shadow: 0px 3px 6px var(--unnamed-color-21b6a8); */
  /* background: #CAF5F5 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 3px 6px #21B6A8; */
  border: none !important;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_heading {
  /* box-shadow: 0px 3px 6px var(--unnamed-color-21b6a8); */
  background: #caf5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #21b6a8;
  border: none !important;
  height: 48px;
  /* display: flex;
  justify-content:flex-start; 
  align-items:flex-start; */
}

.head_text {
  font: normal normal normal 25px Roboto Slab;
  letter-spacing: 0px;
  color: #000000;
}

.form-control {
  height: 26px !important;
}

.height_49 {
  height: 45vh;
  overflow: auto;
}

.new_btn {
  border: none;
  background-color: #4ad187;
}

.cancel_btn {
  border: none !important;
  outline: none !important;
  background-color: #f88894;
  font: normal normal normal 17px/23px Roboto Slab;
  border-radius: 5px;
}

#activityind {
  display: none;
}

.subbuttonheight {
  height: 88vh;
  overflow: auto;
  overflow-x: hidden;
}

.listcss {
  border-bottom: none;
  border: 0.3px solid #707070;
  cursor: pointer;
  min-width: 100px;
  padding: 5px;
}

.listcss:hover {
  background-color: #337ab7;
  color: #fff;
}

.makeStyles-root-1 {
  max-width: 100% !important;
}

.suggestbox {
  padding-left: 0px;
  width: 91%;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #fff;
  outline: none !important;
  list-style: none;
  text-align: left;
  z-index: 20000;
  position: absolute;
}

.suggestboxaccount {
  display: block;
  padding-left: 0px;
  width: auto;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #fff;
  outline: none !important;
  list-style: none;
  text-align: left;
  z-index: 20000;
  /* position: absolute; */
}

ul {
  list-style: none;
}

.suggestboxaccount {
  display: block;
  padding-left: 0px;
  width: auto;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #fff;
  outline: none !important;
  list-style: none;
  text-align: left;
  z-index: 20000;
  /* position: absolute; */
}

.viewcompmg {
  margin-right: 6px;
  margin-left: 4px;
}

.card-list {
  /* width: 85vw; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.card-container {
  display: flex;
  color: #056363;
  flex-direction: column;
  background-color: #5cd1d0 !important;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.card-container:hover {
  transform: scale(1.05);
}

.cur_sor {
  cursor: pointer;
}

.projectName {
  position: absolute;
  bottom: 2em;
  white-space: nowrap;
  height: 3em;
  width: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0.5em;
}

.signature {
  /* position: absolute;
    bottom: 47em;
    left: 1em;
    right: 0em;
    left: 0; */
}

.modalscreen {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-content-screen1 {
  background-color: #fefefe;
  margin: 4% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

/* .modal-content-screen2 {
  background-color: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  overflow: auto;
} */

.modal-content-dashboard {
  background-color: #fefefe;
  margin: 4% auto;
  /* 15% from the top and centered */
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  /* Could be more or less, depending on screen size */
  overflow: auto;
}

.customcardcontainer {
  display: flex;
  color: #056363;
  flex-direction: column;
  background-color: rgba(75, 192, 192, 0.2) !important;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  width: 92% !important;
}

.customcard {
  /* width: 43vw; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-gap: 6px;
}

input[type="text"]:focus {
  border: 2px solid #5cd1d0 !important;
}

input[type="number"]:focus {
  border: 2px solid #5cd1d0 !important;
}

input[type="date"]:focus {
  border: 2px solid #5cd1d0 !important;
}

input[type="email"]:focus {
  border: 2px solid #5cd1d0 !important;
}

textarea:focus {
  border: 2px solid #5cd1d0 !important;
}

select:focus {
  border: 2px solid #5cd1d0 !important;
}

canvas {
  width: 100% !important;
}

.circle-red {
  width: 17px !important;
  height: 17px;
  border-radius: 50%;
  background-color: red;
  margin-top: 2px !important;
}

.circle-green {
  width: 17px !important;
  height: 17px;
  border-radius: 50%;
  background-color: green;
  margin-top: 2px !important;
}

.circle-yellow {
  width: 17px !important;
  height: 17px;
  border-radius: 50%;
  background-color: yellow;
  margin-top: 2px !important;
}

.modal_container {
  overflow: auto;
  height: 68vh;
  padding: 0px !important;
}

.estaric {
  color: red;
  font-size: 1.1em;
}

.att_btn2 {
  background: #4ad187 !important;
}

.att_btn3 {
  background: rgb(160, 155, 155) !important;
}

.att_button {
  border: none;
  color: white;
  outline: none !important;
  border-radius: 5px;
}

.dashboardicon1 {
  height: 50px;
  width: auto;
  padding-left: 10vw;
}

.present {
  border: 1px solid #808080;
  border-radius: 5px;
  width: fit-content;
  background-color: #bff1bf;
}

.checkinattendance {
  border: 1px solid #808080;
  border-radius: 5px;
  width: fit-content;
  background-color: #bff1bf;
}

.absent {
  border: 1px solid #808080;
  border-radius: 5px;
  width: fit-content;
  background-color: #f9a3a3;
}

.reportcommissiondetails,
.reportcommissionprint {
  cursor: pointer;
  font-size: 1.2rem;
}

rect {
  background-color: white !important;
}

.modal-btn {
  cursor: pointer;
  font-size: 20px;
  transition: opacity 0.3s ease;
}

.modal-btn:hover {
  opacity: 0.5;
}

.my-modal {
  background: transparent;
  backdrop-filter: blur(3px);
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  /* width: 500px; */
  height: auto;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal-scroll {
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
}
.modal-ul {
  padding-left: 0;
}
.modal.right .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 8px 15px 5px;
}

.modal.right.fade .modal-dialog {
  top: 160px;
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out top 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out top 0.3s ease-out;
}

/* .modal.right.fade.in .modal-dialog {
  right: 0;
} */

.overlay-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

@page {
  size: A4;
  margin-top: 5mm;
}

@media print {
  .printheader {
    position: fixed;
    top: 0;
  }

  .content-block {
    page-break-inside: avoid;
  }

  /* html, body {
      width: 210mm;
      height: 297mm;
    } */
}

/* .marginclass{
    margin-top: 100px;
  } */
.jss1 {
  max-width: 100% !important;
}

.changeFormTable_height {
  height: 54vh;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21b6a8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #21b6a8;
}

.table_data {
  font-size: 12px !important;
}
